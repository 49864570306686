import SETTINGS from "./settings";

const siteToRegion = {
  frankfurt: "eu-central-1",
  london: "eu-west-2",
};

export const buildOptions = (infra, roomName) => {
  const { release } = SETTINGS;
  const { tenant, domain, site, focus } = SETTINGS[infra];
  const fullDomain = site ? `${site}.${domain}/${tenant}` : domain;
  const options = {
    hosts: {
      domain: domain,
      muc: tenant ? `conference.${tenant}.8x8.vc` : `muc.${domain}`,
      focus,
    },
    deploymentInfo: {
      ...(site ? { userRegion: siteToRegion[site] } : {}),
    },
    serviceUrl: `wss://${fullDomain}/xmpp-websocket?room=${roomName}?release=${release}`,
    websocketKeepAliveUrl: `https://${fullDomain}/_unlock?room=${roomName}`,
    clientNode: "http://jitsi.org/jitsimeet",

    logging: {
      defaultLogLevel: "trace",
      "modules/RTC/TraceablePeerConnection.js": "log",
      "modules/statistics/CallStats.js": "log",
      "modules/xmpp/strophe.util.js": "log",
    },

    enableLayerSuspension: true,
    p2p: {
      enabled: false,
    },
    webrtcIceUdpDisable: false,
    webrtcIceTcpDisable: true,
  };

  console.log("connection options", options);
  return options;
};
