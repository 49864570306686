import React, { useState } from "react";
import EmailValidator from "email-validator";

import { makeStyles, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyle = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "50%",
    margin: "0 auto",
  },
  spacer: {
    marginTop: "4rem",
  },
});

const AskMail = ({ setMail }) => {
  const classes = useStyle();
  const [value, setValue] = useState(
    process.env.NODE_ENV === "production" ? "" : "test@test.fr"
  );

  const error = !EmailValidator.validate(value);

  const handleChange = ({ target: { value } }) => setValue(value);
  const handleClick = () => setMail(value);

  return (
    <div className={classes.root}>
      <div className={classes.spacer} />

      <p>
        Bonjour,
        <br />
        Ce test va prendre moins d’une minutes. N’oubliez pas d’autoriser
        l’accès à votre caméra et votre micro lorsque vous aurez le pop-up de
        demande d’autorisation.
        <br />
        <br />
        L’objectif de ce test : nous assurer que votre configuration matériel et
        réseau sont compatibles avec notre système visio.
      </p>

      <TextField
        label={"Saisissez votre adresse e-mail professionnelle"}
        value={value}
        onChange={handleChange}
        required
        fullWidth
      />

      <div className={classes.spacer} />

      <Button
        color={"primary"}
        variant="contained"
        disabled={!value || error}
        onClick={handleClick}
      >
        Lancer les tests
      </Button>
    </div>
  );
};

export default AskMail;
