import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "70%",
    margin: "0 auto",
  },
  spacer: {
    marginTop: 64,
  },
});

const Summary = ({ failedTests, logs }) => {
  const classes = useStyle();
  const isDev = process.env.NODE_ENV === "development";
  return (
    <div className={classes.root}>
      <div className={classes.spacer} />

      <h2>Résultat</h2>

      {failedTests.length > 0 ? (
        <div>
          Les tests suivants ont échoué :
          <ul>
            {failedTests.map((test) => (
              <li key={test.id}>{test.label}</li>
            ))}
          </ul>
          Le support technique a été informé et vous contactera prochainement.
        </div>
      ) : (
        <div>Tous les tests ont réussi !</div>
      )}
      <div>Vous pouvez maintenant fermer cette page</div>
      {isDev && (
        <>
          <div className={classes.spacer} />
          <div>
            {logs.map((log) => (
              <div key={[log.message, log.time].join("_")}>
                {log.message}
                <br />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
export default Summary;
