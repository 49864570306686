/* eslint-disable */
let interval = null;

export default async (resolve, reject) => {
  // Add a timeout for this test
  setTimeout(() => {
    if (interval) clearInterval(interval);
    return reject("Can not connect to UDP directly to Video Server : Timeout");
  }, 80000);

  interval = setInterval(() => {
    const isDirectUdp =
      window.JITSIroom.connectionQuality._localStats.transport?.[0]?.type ===
        "udp" &&
      window.JITSIroom.connectionQuality._localStats.transport?.[0]
        ?.localCandidateType === "prflx";

    console.log(
      "window.JITSIroom.connectionQuality._localStats.:",
      window.JITSIroom.connectionQuality._localStats
    );
    console.log("isDirectUdp:", isDirectUdp);

    if (document.querySelector("#cameraecho").videoHeight > 0 && isDirectUdp) {
      clearInterval(interval);
      resolve();
    }
  }, 1000);
};
