/* eslint-disable */
export default async (resolve, reject) => {
  let interval = null;
  setTimeout(() => {
    if (interval) clearInterval(interval);
    return reject("Timeout");
  }, 10000);
  try {
    window.JITSIroomFake.on(
      JitsiMeetJS.events.conference.TRACK_ADDED,
      (track) => {
        if (track.type === "video") {
          const element = document.getElementById("cameraecho");
          track.attach(element);
          clearInterval(interval);
          resolve();
        }
      }
    );

    window.JITSIroomFake.on(JitsiMeetJS.events.track.GENERAL, (err) => {
      reject(`track error: ${err}`);
    });
    window.JITSIroomFake.on(JitsiMeetJS.events.track.NOT_FOUND, (err) => {
      reject(`track NOT_FOUND: ${err}`);
    });
    window.JITSIroomFake.on(
      JitsiMeetJS.events.track.PERMISSION_DENIED,
      (err) => {
        reject(`track PERMISSION_DENIED: ${err}`);
      }
    );
    window.JITSIroomFake.on(
      JitsiMeetJS.events.track.TRACK_IS_DISPOSED,
      (track) => {
        reject("TRACK_IS_DISPOSED");
      }
    );
    window.JITSIroomFake.on(
      JitsiMeetJS.events.track.TRACK_NO_STREAM_FOUND,
      (track) => {
        reject("TRACK_NO_STREAM_FOUND");
      }
    );

    await window.JITSIroom.addTrack(window.JITSItrackerAudio);
    await window.JITSIroom.addTrack(window.JITSItrackerVideo);
  } catch (error) {
    reject(error);
  }
};
