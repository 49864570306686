/* eslint-disable */
import { buildOptions } from "../settings/roomOptions";

export default async function(
  resolve,
  reject,
  _addLog,
  { roomName, infra, jwt1, jwt2 }
) {
  const options = buildOptions(infra, roomName);
  console.warn("CONNECTION", infra === "jaas", jwt1, options);
  JitsiMeetJS.init(options);
  window.JITSIconnection = new JitsiMeetJS.JitsiConnection(
    null,
    infra === "jaas" ? jwt1 : null,
    options
  );

  const { defaultLogLevel, ...logging } = options.logging;
  JitsiMeetJS.setLogLevel(defaultLogLevel);
  for (const [loggerId, level] of Object.entries(logging)) {
    JitsiMeetJS.setLogLevelById(level, loggerId);
  }

  window.JITSIconnectionFake = new JitsiMeetJS.JitsiConnection(
    null,
    infra === "jaas" ? jwt2 : null,
    options
  );

  window.JITSIconnection.addEventListener(
    JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
    () => {
      // Depuis qu'on utilise les websocket, il faut laisser un petit temps pour que la connexion soit opérationnelle
      setTimeout(resolve, 2000);
    }
  );

  window.JITSIconnection.addEventListener(
    JitsiMeetJS.events.connection.CONNECTION_FAILED,
    reject
  );

  window.JITSIconnection.addEventListener(
    JitsiMeetJS.events.connection.CONNECTION_ERROR,
    reject
  );

  await window.JITSIconnection.connect();
  await window.JITSIconnectionFake.connect();
}
