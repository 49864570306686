/* eslint-disable */
import { buildOptions } from "../settings/roomOptions";

export default async function(resolve, reject, addLog, { roomName, infra }) {
  window.JITSIroom = await window.JITSIconnection.initJitsiConference(
    roomName,
    buildOptions(infra, roomName)
  );

  // On utilise une deuxième participant factice pour forcer l'upload des vidéo stream
  window.JITSIroomFake = await window.JITSIconnectionFake.initJitsiConference(
    roomName,
    buildOptions(infra, roomName)
  );

  // On définit une qualité par défaut
  const QUALITY = 180;
  await JITSIroom.setReceiverVideoConstraint(QUALITY);
  await JITSIroom.setSenderVideoConstraint(QUALITY);
  await JITSIroomFake.setReceiverVideoConstraint(QUALITY);
  await JITSIroomFake.setSenderVideoConstraint(QUALITY);

  let oneJoined = false;
  const resolveIfBothJoined = () => {
    if (oneJoined) resolve();
    else oneJoined = true;
  };
  window.JITSIroom.on(
    JitsiMeetJS.events.conference.CONFERENCE_JOINED,
    resolveIfBothJoined
  );
  window.JITSIroomFake.on(
    JitsiMeetJS.events.conference.CONFERENCE_JOINED,
    resolveIfBothJoined
  );
  const rejectAndLogs = (err) => {
    addLog(`join-conference error: ${String(err)}`, "error");
    reject(err);
  };
  window.JITSIroom.on(
    JitsiMeetJS.events.conference.CONFERENCE_FAILED,
    rejectAndLogs
  );
  window.JITSIroomFake.on(
    JitsiMeetJS.events.conference.CONFERENCE_FAILED,
    rejectAndLogs
  );
  window.JITSIroom.on(
    JitsiMeetJS.events.conference.CONFERENCE_ERROR,
    rejectAndLogs
  );
  window.JITSIroomFake.on(
    JitsiMeetJS.events.conference.CONFERENCE_ERROR,
    rejectAndLogs
  );

  const bothJoin = () => {
    window.JITSIroom.join();
    window.JITSIroomFake.join();
  };
  bothJoin();

  setTimeout(() => bothJoin, 5000);
  setTimeout(() => rejectAndLogs("Timeout"), 15000);
}
