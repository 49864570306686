import React from "react";

import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "50%",
    margin: "0 auto",
  },
  spacer: {
    marginTop: "4rem",
  },
});

const Loading = () => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <div className={classes.spacer} />

      <div>Chargement...</div>
    </div>
  );
};

export default Loading;
