const clearConnection = async (warn = false) => {
  console.log("clear connection");
  const promises = [
    window.JITSItrackerAudio?.dispose(),
    window.JITSItrackerVideo?.dispose(),
    window.JITSIroom?.leave(),
    window.JITSIroomFake?.leave(),
    window.JITSIconnection?.disconnect(),
    window.JITSIconnectionFake?.disconnect(),
  ];
  try {
    await Promise.all(promises);
  } catch (error) {
    // Silent this error
    if (warn) console.warn("cleaning error", error);
  }
};
export default clearConnection;
