/* eslint-disable */
export default async (resolve, reject) => {
  try {
    // ask permission for video
    await navigator.mediaDevices.getUserMedia({ video: true });

    const tracks = await JitsiMeetJS.createLocalTracks({
      devices: ["video"],
    });
    window.JITSItrackerVideo = tracks[0];

    resolve();
  } catch (error) {
    reject(error);
  }
};
