/* eslint-disable */
export default async (resolve, reject) => {
  // ask permission for audio
  try {
    await navigator.mediaDevices.getUserMedia({ audio: true });

    const tracks = await JitsiMeetJS.createLocalTracks({
      devices: ["audio"],
    });

    window.JITSItrackerAudio = tracks[0];
    resolve();
  } catch (error) {
    reject(error);
  }
};
