/* eslint-disable */
let interval = null;

export default async (resolve, reject) => {
  interval = setInterval(() => {
    if (
      document.querySelector("#cameraecho").videoHeight > 0 &&
      window.JITSIroom.connectionQuality._localStats.transport?.[0]
        ?.localCandidateType === "relay"
    ) {
      clearInterval(interval);
      resolve();
    }
  }, 1000);

  try {
    // Add a timeout for this test
    setTimeout(() => {
      if (interval) clearInterval(interval);
      return reject("Can not connect to TCP to Video Server : Timeout");
    }, 60000);
  } catch (error) {
    reject(error);
  }
};
