/* eslint-disable */
import SETTINGS from "../settings/settings";
import clearConnection from "./clear-connection";

const NetworkSpeed = require("network-speed");
const testNetworkSpeed = new NetworkSpeed();

const LIMIT_DOWN = 700 * 3;
const LIMIT_UP = 600;

export default async (resolve, reject, addLog) => {
  // Add a timeout for this test
  setTimeout(() => {
    return reject(
      `Can not detect the network bandwidth : Timeout on ${SETTINGS.domainBandwidth}:80`
    );
  }, 15000);

  clearConnection(true);

  const baseUrl = `https://${SETTINGS.domainBandwidth}/1.dummy?${Date.now()}`;
  const fileSize = 10000000;
  const speedDown = await testNetworkSpeed.checkDownloadSpeed(
    baseUrl,
    fileSize
  );

  addLog(
    `Download Speed: ${speedDown.kbps}kbps (${speedDown.mbps}Mbps)`,
    speedDown.kbps > LIMIT_DOWN ? "success" : "error"
  );

  const options = {
    hostname: SETTINGS.domainBandwidth,
    port: 443,
    protocol: "https://",
    path: "/catcher-upload/index.php",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const speedUp = await testNetworkSpeed.checkUploadSpeed(options);
  addLog(
    `Upload Speed: ${speedUp.kbps}kbps (${speedUp.mbps}Mbps)`,
    speedUp.kbps > LIMIT_UP ? "success" : "error"
  );

  if (speedUp.kbps > LIMIT_UP && speedDown.kbps > LIMIT_DOWN) resolve();
  else reject();
};
