const settings = {
  infras: JSON.parse(process.env.REACT_APP_INFRAS ?? '["jaas"]'),
  aws: {
    domain: process.env.REACT_APP_JITSI_DOMAIN,
  },
  jaas: {
    domain: "8x8.vc",
    site: process.env.REACT_APP_JAAS_SITE,
    tenant: process.env.REACT_APP_JAAS_TENANT,
    focus: `focus.8x8.vc`,
  },
  release: process.env.REACT_APP_RELEASE,
  domainBandwidth: "bandwidth.kestio.live",
  urlMeteor: process.env.REACT_APP_METEOR_URL,
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
  },
  stopOnFailure: JSON.parse(process.env.REACT_APP_STOP_ON_FAILURE ?? "false"),
};
// console.log("settings", settings);
// console.log("env", process.env);
export default settings;
